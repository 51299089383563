




















































































import Vue from 'vue';
import Component from 'vue-class-component';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import Excel from 'exceljs';
import API, { Types } from '../modules/API';
import { Reports, Props, User, Global } from '@/store';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import { Watch } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';

@Component({
    components: {ConfirmDialog}
})
export default class SetDeviceByPoleNum extends Vue {
    @User.State('project') project;
    @Reports.State('reportsList') devices;
    @Reports.State('devicesCount') devicesCount;
    @Reports.Getter('devicesList_map') devicesList_map;
    @Global.State('readonly_user') readonly_user;
    @Global.Mutation('setPageTitle') setPageTitle;
    @Props.State('list') projectProperties;

    user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

    // general functionality
    loading = false;
    MAIN_COLUMN = 'pole_number';
    // used on mounted
    install_location_fields = [
        'gps_location lat',
        'gps_location lng', 
        'app_location lat', 
        'app_location lng', 
        'gis_location lat', 
        'gis_location lng', 
        'manual_location lat',
        'manual_location lng'
    ];
    saved_columns = []; // columns' possible values, populated from API (the <select> HTML object)
    get names_not_used() { // computing to only show the names for fields that were not selected yet.
        const array_of_relations = this.columns_relations.map((item) => item.nameInDB);
        const updated_array_names_not_used = this.saved_columns.filter((item) => array_of_relations.indexOf(item) === -1);
        return updated_array_names_not_used;
    }

    // variables to read excel to Javascript's handling, used after uploading excel file by user
    workbook: any = null;
    worksheets = [];
    sheetNumber = -1;

    prop_utils = PropUtils;

    // table variables after reading excel
    table = [];
    columns_relations = []; // the <th>s cells of the colum. array of objects.
    pole_column = -1; // the main column is the pole_column. if it's "-1" then it's uninitialized
    showTooltipSetColumn = false;
    
    // dialog props
    showDialog = false;
    dialogText = '';
    dialogType = null;
    dialogCancelText = 'Cancel';
    logs = []; // after uploading 
    devicesFoundInSheet = 0;
    
    // used after the user confirms that the values are good and want to upload to DB.
    // the arrays are used to correlate between units and their poles IDs
    // unit in place x in array, will be correlated to pole ID that's stored in place x in poles_units.
    // we know which unit to update by the unit's related pole ID
    // units and poles_units must have the same length in order to relate them!
    poles_units = [];
    units = [];
    message = '';

    async mounted() {
        this.setPageTitle('Set Device Data By Pole Number');
        this.getFieldNamesForColumns();
        this.getDevicesToUnitsPoles();
    }

    getFieldNamesForColumns() {
        this.saved_columns = this.install_location_fields;
        let select_fields = PropUtils.getProjectPropertyData(this.projectProperties, 'dashboard.selectbox_fields_names_for_excel');
        if (!select_fields) {
            select_fields = PropUtils.getProjectPropertyData(this.projectProperties, 'supported_field_names_of_units');
        }
        if (!select_fields) return;
        this.saved_columns = Object.keys(select_fields).concat(this.saved_columns);
    }

    @Watch('devices')
    @Watch('devicesCount')
    getDevicesToUnitsPoles() {
        if (this.devices.length !== this.devicesCount) return;
        this.devices.forEach((device) => {
            const metaDevice = device['meta.device'];
            metaDevice && metaDevice[this.MAIN_COLUMN]
                ? this.poles_units.push(metaDevice[this.MAIN_COLUMN].toString())
                : this.poles_units.push(null);

            this.units.push(device.id);
        });
    }

    async loadExcel(event) {
        // reset state 
        this.loading = true;
        this.message = '';
        this.logs = [];
        this.worksheets = [];
        this.sheetNumber = -1;
        this.pole_column = -1;
        this.table = [];
        this.columns_relations = [];
        
        try {
            const wb = new Excel.Workbook(),
            reader = new FileReader(),
            that = this;
            const file_type = event.target.files[0].name.split('.').slice(-1)[0];
            if (file_type === 'xlsx'){
                reader.readAsArrayBuffer(event.target.files[0]);
                reader.onload = () => {
                    const buffer = reader.result;
                    if (typeof buffer !== 'string'){
                        wb.xlsx.load(buffer).then(async (workbook) => {
                            workbook.eachSheet((sheet) => this.worksheets.push(sheet.name));
                            that.workbook = workbook;
                            that.loading = false;
                            this.openModal('choose_worksheet');
                        });
                    }
                };
            }else this.message = 'Unsupported file type';
        }catch (e) {}
        this.loading = false;
    }

    styleCell(cell, index: number) {
        if (index === this.pole_column && this.poles_units.indexOf(cell.text.toString()) === -1) return 'background-color: red; color: white;';
        else if (index === this.pole_column && this.poles_units.indexOf(cell.text.toString()) !== -1) return 'background-color: green; color: white;';
        return '';
    }

    async loadSheet(sheetNumber: number) {
        // reset state
        this.devicesFoundInSheet = 0;
        this.table = [];
        this.sheetNumber = sheetNumber;
        this.columns_relations = [];
        this.loading = true;
        this.pole_column = -1;
        const sheet = await this.workbook.worksheets[sheetNumber];
        if (sheet === undefined) alert(this.$t('Error choosing worksheet. Please check the file or decrease its size by separating sheets and try again.'));
        await sheet.getRow(1).eachCell({includeEmpty: true}, (cell: string, cellIndex: number) => this.columns_relations.push({nameInFile: cell, nameInDB: null, key: ('head' + cellIndex.toString())}));
        await sheet.eachRow(async (row, rowIndex: number) => {
            const rowArray = [];
            row.eachCell({ includeEmpty: true }, (cell, cellIndex: number) => rowArray.push({text: cell.toString(), key: ('cell' + cellIndex.toString() + rowIndex.toString())}));
            this.table.push(rowArray);
            this.openModal('choose_pole_id');
        });
        this.loading = false;
    }

    defineAsPoleColumn(i) {
        if (i > -1) this.openModal('set_pole_id');
        if (this.pole_column > -1) this.columns_relations[this.pole_column].nameInDB = null;
        this.pole_column = i;
        if (this.pole_column > -1) this.columns_relations[i].nameInDB = this.MAIN_COLUMN;
    }


    async saveInDB() {
        this.loading = true;
        const selected_meta_device_columns = [];
        const selected_location_columns = [];
        this.columns_relations.forEach((item, index) => {
            if (item.nameInDB && item.nameInDB !== this.MAIN_COLUMN){
                this.install_location_fields.includes(item.nameInDB)
                    ? selected_location_columns.push(index)
                    : selected_meta_device_columns.push(index);
            }
        });
        await this.table.forEach(async (row, i) => {
            if (row[this.pole_column] && !isNaN(row[this.pole_column].text)) {
                const pole_number = row[this.pole_column] && row[this.pole_column].text 
                    ? row[this.pole_column].text.toString()
                    : '';
                
                if (pole_number){
                    const devicesToUpdate = this.findDevicesWithSamePole(pole_number);
                    if (devicesToUpdate.length){
                        this.updateMetaDeviceByPoleNum(devicesToUpdate, selected_meta_device_columns, i, row);
                        this.updateLocationByPoleNum(devicesToUpdate, selected_location_columns, i, row);
                    }
                }else
                    this.logs.push(`problem with row number ${ (1 + i) }. ${this.MAIN_COLUMN} ${row[this.pole_column].text.toString()} is not present in any of the units queried for the project.`);
            }
            else {
                this.logs.push(`problem with row number ${(i + 1)}: ${this.MAIN_COLUMN} is not a number, it's ${row[this.pole_column] ? row[this.pole_column].text.toString() : 'null'}.`);
            }
        });
        this.loading = false;
        this.openModal('saved_to_db');
    }

    async updateMetaDeviceByPoleNum(devices, selected_columns, index, row){
        if (selected_columns.length){
            await Promise.all(devices.map( async (device_id) => {
                const device = this.devicesList_map.get(device_id);
                const metaDevice = this.getMetaDeviceFieldsByRow(device['meta.device'], row, selected_columns);
                try {
                    await API.patch(Types.DEVICES, `/${device_id}/properties`, metaDevice, {query: {name: 'meta.device'}});
                    device['meta.device'] = metaDevice;
                }catch (e) {
                    this.logs.push(`problem with update of meta.device, row number ${ (index + 1) }, device id ${ device_id }: unknown internal error.`);
                }
            }));
        }
    }

    async updateLocationByPoleNum(devices, selected_columns, index, row){
        if (selected_columns.length){
            await Promise.all(devices.map( async (device_id) => {
                const device = this.devicesList_map.get(device_id);
                const install_location = PropUtils.parseProperty('meta.install_location', device);
                this.getLocationFieldsByRow(install_location, row, selected_columns);
                const url = `/${device_id}/properties`;
                const response = await API.patch(
                    Types.DEVICES,
                    url, 
                    install_location, 
                    {query: {name: 'meta.install_location'}}
                );
                if (!response['value']) this.logs.push(`problem with update of meta.install_location, row number ${ (index + 1) }, device id ${ device_id }: unknown internal error.`);
            }));
        }
    }

    findDevicesWithSamePole(pole_number){
        return this.units.filter((device_id, index) => this.poles_units[index] === pole_number);
    }

    getMetaDeviceFieldsByRow(meta_device, row, selected_columns) {
        const meta_device_copy = cloneDeep(meta_device);
        selected_columns.forEach((index) => {
            const value = row && row[index] && row[index].text ? row[index].text : '';
            if (value) {
                meta_device_copy[this.columns_relations[index].nameInDB] = Utils.removeLeadingZeroesAndSpaces(value);
            }
        });
        return PropUtils.getUpdatedUppercaseFields(meta_device_copy);
    }

    getLocationFieldsByRow(install_location, row, selected_columns) {
        selected_columns.forEach((index) => {
            const [field, subfield] = this.columns_relations[index].nameInDB.split(' ');
            const value = row && row[index] && row[index].text ? row[index].text : '';
            if (value) install_location[field][subfield] = value;
        });
    }

    
    // Dialog functions 
    openModal(type: string) {
        this.dialogType = type;
        this.showDialog = true;
        if (type === 'saved_to_db') {
            this.dialogText = this.$t('The file has been received successfully');
            this.dialogCancelText = this.$t('Download Log File');
        }
        if (type === 'set_pole_id') this.dialogText = this.$t('Are you sure that you want to mark this column as Pole ID? a mistake will cause errors because this is a main indication');
        if (type === 'save_in_db') this.dialogText = this.$t('Are you sure that you want to save the uploaded Excel file to the system?');
        if (type === 'choose_worksheet') this.dialogText = this.$t('Please choose the tab you would like to upload first');
        if (type === 'choose_pole_id') this.dialogText = this.$t('Please choose the column of Pole ID to function as the main column');
    }

    cancelModalAction() {
        if (this.dialogType === 'set_pole_id') this.defineAsPoleColumn(-2);
        if (this.dialogType === 'save_in_db') this.dialogType = null;
        if (this.dialogType === 'saved_to_db') this.downloadLog();
        this.closeModal();
    }
    
    closeModal() {
        if (this.dialogType === 'set_pole_id' && this.pole_column > -1) {
            this.showTooltipSetColumn = true; 
            this.setValidPolesBySheet();
        }
        if (this.dialogType === 'save_in_db') this.saveInDB();
        this.dialogType = null;
        this.showDialog = false;
        this.dialogText = '';
        this.dialogCancelText = 'Cancel';
    }

    setValidPolesBySheet(){
        const poleColumnValues = this.table.map((row) => row[this.pole_column]);
        const validPoleNumbers = poleColumnValues.filter((value) => this.poles_units.indexOf(value.text.toString()) !== -1 && value.text.toString());
        this.devicesFoundInSheet = this.poles_units.reduce((acc, current_pole) => {
            return validPoleNumbers.includes(current_pole)
                ? acc + 1 
                : acc ;
        }, 0);
    }   

    downloadLog() {
        const date = new Date(), type = 'text/plain', 
        name = `logs_${date.getMinutes().toString()}_${date.getHours().toString()}_${date.getMonth().toString()}_${date.getFullYear().toString()}.txt`,
        text = this.logs.join('  \n'),
        blob = new Blob([text], {type}),
        url = window.URL.createObjectURL(blob),
        link = document.createElement('a');
        link.download = name;
        link.href = url;
        link.click();
        this.logs = [];
    }
}
